.start-container {
  background: rgb(0, 0, 0, 0.2) url('../images/Sebstah1.webp') center center/cover no-repeat;
  background-blend-mode: darken;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.0);
  object-fit: contain;
  position: relative;
}

.left-side-on-image {
  position: absolute;
  width: 50%;
  height: 100%;
  background-color: #ff924fe3;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 15% 15% 0 / 50%;
}

.start-button-container {
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 10%;
  margin-left: 10%;
}

.changing-text {
  position: absolute;
  opacity: 1;
  transition: opacity 2s;
  padding: 20px;
}

.fade-out {
  opacity: 0;
}

@media (max-width: 460px) {
  .start-container {
    min-height: 70vh;
  }
  .changing-text{
    font-size: 28px
  }
}

@media (max-width: 360px) {
  .changing-text{
    font-size: 20px
  }
}