.tjanster-container {
 margin-top: 5%;
 margin-bottom: 5%;
 margin-right: 10%;
 margin-left: 10%;
}

.tjanster-border-line {
    border-bottom: solid 2px black;
  }


    
    
  