.kontakt-container{
  margin-top: 5%;
  margin-bottom: 5%;
  margin-right: 10%;
  margin-left: 10%;
}

.kontakt-container-h1-p{
    padding-right: 20%;
    margin-right: 5%;
    margin-left: 5%;
}

.kontakt-container-h1{
    color: #FF924F;
    font-size: 36px;
    margin-top: 5%;
}

.kontakt-container-p{
    color: black;
    font-size: 30px;
    margin-top: 20px;
}

.kontakt-container-p1{
  color: black;
  font-size: 20px;
  margin-top: 20px;
}

.button-kontakt-container{
    background-color: white;
    border: none;
    border-radius: 30px;
    padding: 10px 15px 10px 15px;
    font-size: 15px;
    margin-right: 30px;
    margin-top: 5%;
    color: #FF924F;
    font-weight: bold;
    cursor: pointer;
}
.button-kontakt-container:hover{
  background-color: rgb(219, 219, 219);
}

.kontakt-kontaktaoss-h1{
    color: #FF924F;
    font-size: 36px;
    text-align: center;
    margin-bottom: 50px;
}

.form-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    
}

.form-group{
   display: grid;
   width: 100%;
   padding-left: 10%;
   padding-right: 10%;
}


.form-group input{
   padding: 10px;
   border-radius: 5px;
   border: none; 
}

.form-group textarea{
    height: 100px;
    padding: 10px;
    border-radius: 5px;
    border: none;
    resize: none;
    font-size: 20px
 }

  .form-label-kontakt {
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10px;
    font-weight: bold;
  }
  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* add height to the container */
  }

  .sumbit-kontaktaoss{
    display: flex;
    border: none;
    font-size: 20px;
    color: #FF924F;
    font-weight: bold;
    cursor: pointer;
    margin-top: 3%;
    background-color: #EDEBEB;
  }
  .sumbit-kontaktaoss:hover{
    color: #FF924F;
    
  }

  .whole-form{
    margin-right: 5%;
    margin-left: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }

  .chossen-package{
    color: #000000;
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
  }

  input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@media (max-width: 960px) {
  .kontakt-container-h1{
    font-size: 30px;
    margin-top: 20%;
}

.kontakt-container-p{
    font-size: 20px;

}

.kontakt-container-p1{
  font-size: 14px;
 
}
.kontakt-kontaktaoss-h1{
  font-size: 30px;
}
}

@media (max-width: 700px) {
.button-kontakt-container {
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 5px 10px 5px 10px;
  font-size: 12px;
  margin-right: 20px;
  margin-top: 20px;
  font-weight: bold;
  cursor: pointer;
}
}

@media (max-width: 560px) {
  .button-kontakt-container {
    border-radius: 10px;
    padding: 5px 5px 5px 5px;
    font-size: 10px;
  }
  .kontakt-container-p{
    font-size: 18px;

}

.whole-form{
  margin-right: 0%;
  margin-left: 0%;
  }
 
  .kontakt-kontaktaoss-h1{
    color: #FF924F;
    font-size: 30px;
    text-align: center;
    margin-bottom: 25px;
    
}
.form-group input{
  padding: 6px;
  border-radius: 5px;
  
}

.form-label-kontakt {
  font-size: 13px;
  margin-bottom: 10px;
  margin-top: 10px;
}
.form-group{
  display: grid;
  width: 100%;
  padding-left: 3%;
  padding-right: 3%;
}
.form-group textarea{
  height: 90px;
  padding: 10px;
  border-radius: 5px;
  border: none;
  resize: none;
  font-size: 15px
}
}
@media (max-width: 370px) {
.form-row{
  grid-template-columns: 1fr ;
  
}
}
