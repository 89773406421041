.card {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
    width: 100%;
}
  
.container {
    display: flex;
    flex-direction: column;
    padding: 2px 16px;
    background-color: white;
    box-shadow: 2px 8px 10px 5px #9b9b9b;
    border-radius: 20px;
    padding: 30px;
    margin: 50px;
    width: 300px;
  }

.card-header{
    padding-bottom: 15px;
}

.card-price{
    padding-bottom: 15px;
}

.card-text{
    padding-bottom: 15px;
}

.card-text p{
    padding-bottom: 5px;
}

.price-header{
    color: #FF924F;
    font-size: 36px;
    text-align: center;
  }

.icon-card-check{
    margin-right: 8px;
    color: #FF924F;
}

.icon-card-x{
    margin-right: 8px;
    color: #fd0000;
}

.button-price-kontakt{
    border-radius: 30px;
    border: none;
    font-weight: bold;
    cursor: pointer;
    font-size: 15px;
    background-color: #FF924F;
    color: black;
    padding: 8px;
    text-decoration: none;
    text-align: center;

}

.button-price-kontakt:hover{
    background-color: #ffa064;
}

@media (max-width: 960px) {
    .card{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
    }
    .price-header{
        font-size: 30px;
    }
}

@media (max-width: 360px) {
    .container{
        width: 225px;
        margin: 20px;
    }
    
}
