.omoss-container{
    margin-top: 5%;
    margin-bottom: 5%;
    margin-right: 10%;
    margin-left: 10%;
}

.omoss-container-h1{
    color: #FF924F;
    font-size: 36px;
    margin-top: 5%;
}

.omoss-container-p {
    color: black;
    font-size: 22px;
    margin-top: 20px;
}

.omoss-container-h1-p{
    padding-right: 20%;
    margin-right: 5%;
    margin-left: 5%;
}

.omoss-border-line{
    border-bottom: solid 2px black;
    margin-top: 50px;
    margin-bottom: 50px;
}

.omoss-card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    
}

.omoss-card-container{
    margin-right: 5%;
    margin-left: 5%;
}


.thomas-image{
    width: 300px;
    height: 325px;
    
}

.card-info {
    margin-top: 10px;
    text-align: center;
  }

  .card-info-h1 {
    color: #FF924F;
  }

  .card-info-p-hover{
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .card-info-p-hover:hover{
    color: #FF924F;
  }

  .card-info-p{
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .omoss-foretagsledning-h1{
    color: #FF924F;
    font-size: 36px;
    text-align: center;
    margin-bottom: 50px;
    
  }

  .card-container {
       display: flex;
       flex-direction: column;
        max-width: 400px;
        padding: 10px;
        box-sizing: border-box;
        box-shadow: 2px 8px 10px 5px #9b9b9b;
        margin: 0 25px;
     }

  .card-container-outer {
    display: flex;
    justify-content: space-evenly;
  }

  .icon-card-info{
    margin-right: 8px;
  }

  

@media (max-width: 960px) {
  .card-container{
    margin: 0 15px;
  }
    .omoss-container-h1 {
      font-size: 30px;
      margin-top: 20%;
    }
    .omoss-container-p {
        font-size: 22px;
    }
    .omoss-container-h1-p{
        padding-right: 0%;
    }
    .omoss-foretagsledning-h1{
        font-size: 30px;
    }
    .omoss-foretagsledning-h1{
       
    }
    .thomas-image{
      width: 230px;
  }

}

@media (max-width: 560px) {
  .card-info-h1{
    font-size:20px;
  }
    .card-info-p{
    font-size:16px;
    }
    .thomas-image{
      width: 200px;
      height: 250px;
  }
  .omoss-container-p{
    font-size: 18px;
  }

  }
  @media (max-width: 460px) {
    .card-container-outer{
      flex-direction: column;
      justify-content: center;
      align-items: center;

      }
      .card-container {
        margin: 20px 0px;
        
     }
 }


    @media(max-width: 360px) {
      .thomas-image{
        width: 180px;
        height: 250px;
      }
    }


  