@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Dosis', 'PT Sans', sans-serif;
  
}

body {
  background-color: #EDEBEB;
}