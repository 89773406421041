.container-footer {
  display: flex;
  color: #FF924F;
  background-color: white;
}

.footer-logo img {
  height: 50px;
  width: 200px;
}

.footer-text {
  font-size: 20px;
}

.footer-row {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
}

@media screen and (max-width: 860px) {
  .footer-row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .footer-text {
    margin-bottom: 15px;
  }
}