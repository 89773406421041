  .button-kontakt {
    float: right;
    background-color: white;
    border: none;
    border-radius: 30px;
    padding: 1% 3% 1% 3%;
    color: #FF924F;
    font-weight: bold;
    cursor: pointer;
    font-size: 15px;
    margin-top: 20px;
  }
  .button-kontakt:hover{
    background-color: rgb(219, 219, 219);
  }
  
  .tjanster-grid-container-upper {
      display: grid;
      grid-template-columns: 50% 50%;
      margin-top: 5%;
      margin-bottom: 5%;
    }

    .tjanster-header1{
      font-weight: 100;
      letter-spacing: 10px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    
    .tjanster-grid-item-upper-right-image {
      height: 45vh;
      width: 30vw;
      display: block;
      margin-left: auto;
      margin-right: auto;
      box-shadow: 2px 3px 10px 2px #9b9b9b;
      border-radius: 1%;
    }
     
    .tjanster-grid-item-upper-left{
      padding: 0% 5% 5% 5%;
    }
    
    .tjanster-grid-item-upper-left h1 {
      color: #FF924F;
      font-size: 36px;
    }
    
    .tjanster-grid-item-upper-left-p1 {
      color: black;
      font-size: 22px;
    }
    
    @media (max-width: 960px) {
      .button-kontakt{
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .tjanster-grid-item-upper-left h1 {
      text-align: center;
      padding-top: 2%;
      font-size: 30px;

      }

      .tjanster-header1{
        text-align: center;
      }
    
      .tjanster-grid-item-upper-left-p1 {
        text-align: center;
        padding: 2%;
      }
    
      .tjanster-grid-item-upper-right img {
        height: 40vh;
        width: 60vw;
      }
      .tjanster-grid-container-upper {
        grid-template-columns: 1fr;
        grid-template-areas: "image" "text";
        margin-top: 10%;
        
        /* DET ÄR DENNA!!! */
      }
      .tjanster-grid-item-upper-right {
        grid-area: image;
      }
      
    }
    
    
    @media (max-width: 680px) {
      .tjanster-grid-item-upper-left h1 {
        text-align: center;
        padding: 10px;
      }
      .tjanster-grid-item-upper-left-p1  {
        padding: 10px;
        font-size: 18px;
      }
      .tjanster-grid-item-upper-left{
        padding: 0% 0% 0% 0%;
      }
      
      .tjanster-grid-item-upper-right img {
        height: 40vh;
        width: 70vw;
      }
    }

      @media (max-width: 560px) {
        
        .tjanster-grid-item-upper-right img {
         
          height: 30vh;
          width: 75vw;

        }
        .tjanster-grid-item-upper-left{
          margin-top: 5%;
        }
       
          .tjanster-grid-item-upper-left-p1  {
            padding: 0px;
            font-size: 18px;
          }
  }

  @media (max-width: 280px) {
    .tjanster-grid-item-upper-right img {
      height: 30vh;
      width: 75vw;

    }
   
    
  }