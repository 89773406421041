.container-navbar {
    display: flex;
    padding-right: 10%;
    padding-left: 10%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    background-color: white;
  }
  
  .bars {
    color: #FF924F;
    font-size: 20px;
  }
  
  .navbar {
    height: 100px;
    background-color: 'white';
    position: sticky;
    top: 0;
    width: 100%;
    box-shadow: 5px 4px 4px #9b9b9b;
    z-index: 9999; 
  }
 
  
  .logo {
    cursor: pointer;
  }
  
  .menu-icon {
    display: none;
  }
  
  .nav-elements {
    cursor: pointer;
  }
  
  .nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
  }
  
  .nav-elements ul li {
    padding-left: 50px;
    padding-right: 50px;
  }
  
  .nav-elements ul a {
    font-size: 18px;
    font-weight: bold;
    color: #FF924F;
    text-decoration: none;
  }
  
  @media (max-width: 1050px) {
    .menu-icon {
      display: block;
      cursor: pointer;
    }
  
    .nav-elements {
      position: absolute;
      right: 0;
      top: 75px;
      background-color: white;
      width: 0px;
      height: calc(200px);
      transition: all 0.3s ease-in;
      overflow: hidden;
    }
  
    .nav-elements.active {
      width: 100%;
      text-align: center;
      justify-content: center;
  
    }
  
    .nav-elements ul {
      display: flex;
      flex-direction: column;
    }
  
    .nav-elements ul li {
      margin-right: unset;
      margin-top: 22px;
    }
  
  }

  .nav-links.active {
    border-bottom: 2px solid #FF924F;
  }

  .logo-size{
    height: 50px;
    width: 200px;
  }